(function ($) {

    // $.proofguide('/wp-content/themes/pp-v1.2-children/kim-logan/resources/guide.jpg');

    $('#bcorr-lake-oconee-search').click(function (e) {
        e.preventDefault();
        $('#bcorr-map-oconee').addClass('bcorr-show').removeClass('bcorr-hide');
        $('#bcorr-map-sinclair').addClass('bcorr-hide').removeClass('bcorr-show');
    });

    $('#bcorr-lake-sinclair-search').click(function (e) {
        e.preventDefault();
        $('#bcorr-map-sinclair').addClass('bcorr-show').removeClass('bcorr-hide');
        $('#bcorr-map-oconee').addClass('bcorr-hide').removeClass('bcorr-show');
    });

})(jQuery);

